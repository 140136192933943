import './App.css';
import { motion } from 'framer-motion';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PaintingsGallery from './PaintingsGallery';
// import { getStorage } from 'firebase/storage';
// import app from './firebase'; // Import your Firebase app instance

// const storage = getStorage(app);

const ProjectCard = ({ project }) => (
  <div className="experience-item">
    <div className="experience-header">
      <h3 className="experience-title">{project.title}</h3>
    </div>
    <p className="experience-role">{project.role}</p>
    <p className="experience-description">{project.description}</p>
    <a href={project.url} target="_blank" rel="noopener noreferrer" className="portfolio-link">
      View <span className="arrow">↗</span>
    </a>
  </div>
);

function App() {
  // const [paintingThumbnail, setPaintingThumbnail] = useState('/paintings-thumbnail.jpg');

  // useEffect(() => {
  //   const fetchRandomPainting = async () => {
  //     const listRef = ref(storage, 'paintings');
  //     try {
  //       const res = await listAll(listRef);
  //       if (res.items.length > 0) {
  //         const randomIndex = Math.floor(Math.random() * res.items.length);
  //         const randomPaintingRef = res.items[randomIndex];
  //         const url = await getDownloadURL(randomPaintingRef);
  //         setPaintingThumbnail(url);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching random painting:", error);
  //     }
  //   };

  //   fetchRandomPainting();
  // }, []);

  const projectData = [
    {
      title: "Supertutor",
      description: "Learn more in less time with easy-to-use AI tools",
      role: "Founder & Developer",
      url: "https://supertutorai.co"
    },
    {
      title: "tuttO Game",
      description: "tuttO is the spicy new party game where laughter leads to love...or at least some unforgettable fun",
      role: "Cofounder & Developer",
      url: "https://tuttoplay.com/"
    },
    {
      title: "Big Signal",
      description: "IOS personal safety app emergency alerts to trusted contacts with location tracking",
      role: "Cofounder & Developer",
            url: "https://apps.apple.com/kg/app/big-signal-personal-safety/id6472224823"
    }
  ];

  const HomePage = () => (
    <div className="main-content">
      <section className="subtitle-section">
        <p className="subtitle">Creative Technologist</p>
      </section>

      <section className="projects-section">
        <div className="section-header">
          <span className="section-label">/ PROJECTS</span>
        </div>
        <div className="experience-list">
          {projectData.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </section>

      <section className="experience-section">
        <div className="section-header">
          <span className="section-label">/ EXPERIENCE</span>
        </div>
        <div className="experience-list">
          <ExperienceItem
            company="Tesla"
            role="Lead Autopilot Data Specialist"
            duration="2021"
            description="Led data analysis initiatives for autonomous driving systems"
          />
          <ExperienceItem
            company="Northwell Health"
            // role="Creative "
            duration="2020 — 2021"
            description="Developed interactive art installations for healthcare exhibitions"
          />
          <ExperienceItem
            company="Art Portfolio"
            // role="Digital Artist"
            duration="2016 — Present"
            description="Create paintings, illustrations, animation & short films using Procreate and traditional media."
          >
            <Link to="/paintings" className="portfolio-link">
              View Portfolio <span className="arrow">↗</span>
            </Link>
          </ExperienceItem>
        </div>
      </section>
    </div>
  );

  return (
    <Router>
      <div className="App">
        <div className="animated-bg"></div>
        <header className="header">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <motion.h1
              className="studio-name"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Adam Jonah Studio
            </motion.h1>
          </Link>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/paintings" element={<PaintingsGallery />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <div className="section-header">
        <span className="section-label">/ CONNECT</span>
      </div>
      <div className="contact-links">
        <a href="https://github.com/adamjonah" target="_blank" rel="noopener noreferrer" className="contact-link">
          GitHub <span className="arrow">↗</span>
        </a>
        <a href="https://linkedin.com/in/adamjonah" target="_blank" rel="noopener noreferrer" className="contact-link">
          LinkedIn <span className="arrow">↗</span>
        </a>
        <a href="https://x.com/adamjonah_" target="_blank" rel="noopener noreferrer" className="contact-link">
          X <span className="arrow">↗</span>
        </a>
        <a href="https://instagram.com/adamjonah_" target="_blank" rel="noopener noreferrer" className="contact-link">
          Instagram <span className="arrow">↗</span>
        </a>
        <a href="mailto:adamjonahstudio@gmail.com" className="contact-link">
          Email <span className="arrow">↗</span>
        </a>
      </div>
    </div>
    <p className="copyright">© {new Date().getFullYear()} Adam Jonah Studio</p>
  </footer>
);

const ExperienceItem = ({ company, role, duration, description, children }) => (
  <div className="experience-item">
    <div className="experience-header">
      <h3 className="experience-title">{company}</h3>
      <span className="duration">{duration}</span>
    </div>
    <p className="experience-role">{role}</p>
    <p className="experience-description">{description}</p>
    {children && <div className="experience-actions">{children}</div>}
  </div>
);

export default App;
